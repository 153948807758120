<template>
  <div v-html="html" style="margin: 20px;"></div>
</template>

<script>
import { selectOperation } from '@/api/login'

export default {
  data () {
    return {
      html: ''
    }
  },
  async created () {
    const res = await selectOperation({
      firstKey: 'agreement',
      secondKey: 'econsent_content'
    })
    this.html = res.content
  }
}
</script>

<style scoped>
</style>
