<template>
  <div id="userLayout">
    <div class="container">
      <section class="user-layout-lang">
        <!-- <select-lang class="select-lang-trigger"/> -->
      </section>

      <section class="user-layout-content">
        <div class="main">
          <img src="./img/login_bg.png" class="prime-logo">
          <h2><center>{{ isForget ? '忘记密码' : '登 录' }}</center></h2>
          <div class="form">
            <forget v-if="isForget"></forget>
            <login-form v-else ></login-form>
          </div>
        </div>
      </section>
      <section class="copyright">

        <p>Copyright © 2021 杭州璞睿生命科技有限公司</p>
      </section>

    </div>

  </div>
</template>

<script>

import LoginForm from './loginForm'
import Forget from './forget'
export default {
  name: 'UserLayout',
  components: { LoginForm, Forget },
  data () {
    return {
      isForget: false

    }
  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
#userLayout {
  height: 100%;

  .container {
    width: 100%;
    min-height: 100%;
    background: linear-gradient(180deg, #E0F2FE 0%, #97E9F3 100%);
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .user-layout-lang {
      width: 100%;
      height: 40px;
      line-height: 44px;
      text-align: right;

      .select-lang-trigger {
        cursor: pointer;
        padding: 12px;
        margin-right: 24px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: 18px;
        vertical-align: middle;
      }
    }

    .user-layout-content {
      width: 380px;
      height: 580px;
      display: flex;
      box-shadow: 0 40px 70px -20px rgba(0, 88, 200, 0.2);

      .show-image {
        width: 350px;
        height: 100%;
        background: url("./img/login.png") no-repeat;
        background-size: 100% 100%;
      }

      .main {
        flex: 1;
        background: #fff;

        .prime-logo {
          width: 100%;
          margin-bottom: 20px;
        }

        h1 {
          margin-bottom: 0;

          span {
            font-size: 40px;
            font-weight: bold;
          }

          em {
            font-size: 20px;
            font-weight: bold;
            color: #1CC2FD;
            font-style: normal;
          }
        }

        h2 {
          font-size: 20px;
          font-weight: bold;
          color: #0F121B;
          margin-bottom: 30px;
        }

        .form{
          padding: 0px 30px 40px 30px;
        }
      }
    }

    .copyright {
      font-size: 11px;
      font-weight: 400;
      line-height: 18px;
      color: #00A3B4;
      opacity: 1;
      padding-bottom: 20px;
      text-align: center;
      span {
        cursor: pointer;
      }
    }
  }
}
</style>
