<template>
  <div >
    <a-form
      id="formLogin"
      class="user-layout-login"
      ref="formLogin"
      :form="form"
      @submit="handleSubmit"
    >
      <a-form-item>
        <a-input
          size="large"
          type="text"
          placeholder="请输入用户名"
          v-decorator="[
            'username',
            {rules: [{ required: true, message: '请输入用户名' }], validateTrigger: 'change'}
          ]"
        >
          <a-icon slot="prefix" type="user" :style="{ color: 'rgba(0,0,0,.25)' }"/>
        </a-input>
      </a-form-item>

      <a-form-item>
        <a-input-password
          size="large"
          placeholder="请输入密码"
          v-decorator="[
            'pwd',
            {rules: [{ required: true, message: '请输入密码' }], validateTrigger: 'blur'}
          ]"
        >
          <a-icon slot="prefix" type="lock" :style="{ color: 'rgba(0,0,0,.25)' }"/>
        </a-input-password>
      </a-form-item>

      <a-form-item class="agree">
        <a-checkbox @change="onChange" :checked="check">
          我同意并授权<a :href="privacyUrl" target="_blank">《璞睿隐私政策》</a>
        </a-checkbox>
      </a-form-item>

      <a-form-item style="margin-top:10px">
        <a-button
          size="large"
          type="primary"
          htmlType="submit"
          class="login-button"
          :loading="state.loginBtn"
          :disabled="state.loginBtn"
        >登录</a-button>
        <div class="forget">
          <div class="forget-btn" @click="changePassword">忘记密码</div>
        </div>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
// import md5 from 'md5'
import { mapActions } from 'vuex'
import { timeFix } from '@/utils/util'
import { firtSign } from '@api/login'

export default {
  components: {

  },
  data () {
    return {
      check: false,
      privacyUrl: location.origin + '/privacy',
      form: this.$form.createForm(this),
      state: {
        loginBtn: false
      }
    }
  },
  created () {
    this.fetFirstSign()
  },
  methods: {
    ...mapActions(['Login', 'Logout']),
    // handler
    handleSubmit (e) {
      e.preventDefault()
      const {
        form: { validateFields },
        state,
        Login
      } = this

      state.loginBtn = true

      const validateFieldsKey = ['username', 'pwd']

      validateFields(validateFieldsKey, { force: true }, (err, values) => {
        if (!err) {
          const loginParams = {}
          loginParams.account = values.username
          // loginParams.pwd = md5(values.pwd)
          loginParams.pwd = values.pwd
          loginParams.terminal = this.$terminal
          console.log('login form', loginParams)
          Login(loginParams)
            .then((res) => this.loginSuccess(res))
            .catch(err => this.requestFailed(err))
            .finally(() => {
              state.loginBtn = false
            })
        } else {
          setTimeout(() => {
            state.loginBtn = false
          }, 600)
        }
      })
    },
    loginSuccess (res) {
      this.$router.push({ path: '/' })
      // 延迟 1 秒显示欢迎信息
      setTimeout(() => {
        this.$notification.success({
          message: '欢迎',
          description: `${timeFix()}，欢迎回来`
        })
      }, 1000)
    },
    requestFailed (err) {
      console.log('====登录失败====', (err))
    },
    changePassword () {
      this.$parent.isForget = true
    },
    async fetFirstSign () {
      const ifFirstSign = await firtSign()
      this.check = !ifFirstSign
    },
    onChange (e) {
      this.check = e.target.checked
    }
  }
}
</script>

<style lang="less" scoped>
.user-layout-login {
  label {
    font-size: 14px;
  }

  ::v-deep .ant-input-suffix {
    color: #ABB8CE;
  }
  /* 去除谷歌浏览器中input自动填充背景 */
  ::v-deep input {
    box-shadow: 0 0 0 1000px #fff inset;
  }

  button.login-button {
    padding: 0 15px;
    font-size: 16px;
    height: 46px;
    width: 100%;
    background: #43D8D2;
    border-color: #43D8D2;
  }
  .forget {
    font-size: 14px;
    color: #ABB8CE;
    text-align: center;
    position: relative;

    &:after {
      content: '';
      display: block;
      border-top: 1px solid #E5EBF3;
      width: 100%;
      height: 0;
      position: absolute;
      top: 50%;
      left: 0;
      z-index: 3;
    }

    .forget-btn {
      position: relative;
      display: inline-block;
      background: #fff;
      padding: 0 13px;
      z-index: 4;
      cursor: pointer;
    }
  }
  .agree {
    margin: 0;
    margin-top: -10px;
    span {
      color: #43D8D2;
    }
  }
}
</style>
